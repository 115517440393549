import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';

import Logo from '../Logo';

const useStyles = makeStyles((theme) => ({
  header: {
    backgroundColor: '#edc421',
    minHeight: '10rem',
    textAlign: 'center',
    paddingTop: '3rem',
    paddingBottom: '4rem',
  },

  logo: {
    margin: 'auto',
    [theme.breakpoints.down('sm')]: {
      width: '40%',
    },
    [theme.breakpoints.up('md')]: {
      // calculate width using fixed breakpoint width
      width: '' + (0.4 * 960) + 'px',
    },
    cursor: 'pointer',
  },
}));

export default function Header() {
  const classes = useStyles();
  const history = useHistory();
  return (
    <header className={classes.header}>
      <div className={classes.logo} onClick={() => history.push('/')}>
        <Logo />
      </div>
    </header>
  );
}
