import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';

import Footer from '../Footer';
import Header from '../Header';
import Home from '../pages/Home';
import PrivacyPolicy from '../pages/PrivacyPolicy';
import ScrollToTop from '../ScrollToTop';
import TermsConditions from '../pages/TermsConditions';

export default function AppRouter() {
  return (
    <Router>
      <ScrollToTop />
      <Header />
      <main>
        <Switch>
          <Route path="/free/privacy-policy">
            <PrivacyPolicy />
          </Route>
          <Route path="/free/terms-conditions">
            <TermsConditions />
          </Route>
          <Route path="/">
            <Home />
          </Route>
        </Switch>
      </main>
      <Footer />
    </Router>
  );
}
