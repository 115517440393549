import CssBaseline from '@material-ui/core/CssBaseline';

import Router from '../Router';

function App() {
  return (
    <>
      <CssBaseline />
      <Router />
    </>
  );
}

export default App;
