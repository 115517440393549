import { useMemo, forwardRef } from 'react';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import MaterialUILink from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';

import Copyright from '../Copyright';

// See example:
// https://github.com/mui-org/material-ui/blob/master/docs/src/pages/getting-started/templates/pricing/Pricing.js

const useStyles = makeStyles((theme) => ({
  footer: {
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(6),
  },
  ul: {
    margin: 0,
    padding: 0,
    listStyle: 'none',
  },
  item: {
    textAlign: 'center',
  },
}));

function FooterLink(props) {
  const { to } = props;
  const CustomLink = useMemo(
    () => forwardRef(
      (linkProps, ref) => <Link ref={ref} to={to} {...linkProps} />,
    ),
    [to],
  );
  return (
    <MaterialUILink
      href={props.href}
      variant="subtitle1"
      color="textSecondary"
      component={CustomLink}
    >
      {props.children}
    </MaterialUILink>
  );
}


export default function Footer() {
  const classes = useStyles();
  return (
    <Container maxWidth="md" component="footer" className={classes.footer}>
      <Grid container spacing={4} justify="space-evenly">
        <Grid item xs={12} sm={6}>
          <Typography variant="h6" color="textPrimary" gutterBottom>
            Game Cards
          </Typography>
          <ul className={classes.ul}>
            <li>
              <FooterLink to="/free/privacy-policy">
                Privacy Policy
              </FooterLink>
            </li>
            <li>
              <FooterLink to="/free/terms-conditions">
                Terms & Conditions
              </FooterLink>
            </li>
          </ul>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="h6" color="textPrimary" gutterBottom>
            Game Cards Premium
          </Typography>
          <ul className={classes.ul}>
            <li>
              <FooterLink to="/premium/privacy-policy">
                Privacy Policy
              </FooterLink>
            </li>
            <li>
              <FooterLink to="/premium/terms-conditions">
                Terms & Conditions
              </FooterLink>
            </li>
          </ul>
        </Grid>
      </Grid>

      <Box mt={5}>
        <Copyright />
      </Box>
    </Container>
  );
}
